/* USE ONLY FOR GHOST PURPOSES */
/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
*,
*::before,
*::after {
    box-sizing: inherit;
}
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
    -webkit-tap-highlight-color: transparent;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}
html,
html a,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
dl,
th,
dt,
input,
textarea,
span,
div {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}
p {
    box-sizing: border-box;
    margin-bottom: 1rem;
    margin-top: 0px;
    font-size: 15px;
    color: #343d48;
    font-weight: 400;
    line-height: 1.87;
}
@media screen and (min-width: 576px) {
    p {
        font-size: 15px;
    }
}
@media screen and (min-width: 768px) {
    p {
        font-size: 15px;
    }
}
@media screen and (min-width: 991px) {
    p {
        font-size: 15px;
    }
}
/* @media only screen and (min-width: 1600px) {
    body {
        background-color: #ddd;
    }
} */
a {
    color: #dd637e;
}
/*---------------------------------
-        ESNTLS
-----------------------------------*/
.wrapper {
    width: 100%;
    min-width: 320px;
    margin: 0 auto;
    background-color: #fff;
    transition: transform 0.25s ease-in-out;
    position: relative;
}
/*---------------------------------
-        UTILITIES
-----------------------------------*/
.opacity5 {
    opacity: 0.5;
}
.opacity8 {
    opacity: 0.8;
}
.strong {
    font-weight: 700;
}
.pt-6 {
    padding-top: 2rem;
}
.pt-8 {
    padding-top: 4rem;
}
.pb-6 {
    padding-bottom: 2rem;
}
.pb-8 {
    padding-bottom: 4rem;
}
.mt-6 {
    margin-top: 2rem;
}
.mt-7 {
    margin-top: 3rem;
}
.mt-8 {
    margin-top: 4rem;
}
.mb-6 {
    margin-bottom: 2rem;
}
.mb-7 {
    margin-bottom: 3rem;
}
.mb-8 {
    margin-bottom: 4rem;
}
.no-gutter-center {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.container-fullwidth {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
/* MODAL FIXED */
.modal-full {
    min-width: 100%;
    margin: 0;
}
.modal-full .modal-content {
    min-height: 100vh;
}

.modal-full .modal-header {
    position: fixed;
    top: 0;
    z-index: 9000000 !important;
    width: 100%;
    background: #ffff;
}
.modal-full .modal-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9000000 !important;
    background: #ffff;
    padding: 10px;
    border-radius: 0px;
}
.modal-full .modal-body {
    height: 100%;
    padding-top: 70px;
    padding-bottom: 70px;
}
/* .body-contained {
  height: 100%;
} */
/* END MODAL FIXED */
.nostyleul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}
.zIndexUp {
    z-index: 5000 !important;
}
.no-gutter {
    padding: 0px !important;
}
.slick-prev {
    z-index: 9 !important;
    left: 8px;
    transform: scale(1.2);
    opacity: 0.5;
    transition: all 0.3s;
}
.slick-dots {
    bottom: 20px !important;
}
.slick-dots li button:before {
    font-size: 9px;
    color: #fff !important;
}
.slick-next {
    opacity: 0.5;
    right: 8px;
    z-index: 10;
    transform: scale(1.2);
    transition: all 0.3s;
}
.slick-prev:hover,
.slick-next:hover {
    opacity: 1;
}
.form-group-sm label {
    margin-bottom: 0;
}
.form-group-sm {
    margin-bottom: 5px;
}
.danger {
    color: red;
}
section {
    padding-top: 60px;
    position: relative;
}
/*---------------------------------
-        ACCORDION
-----------------------------------*/
.accordion__item {
    border: 1px solid #eff2f5;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 20px 30px 0px 30px;
}
.accordion__title {
    padding: 23px 30px;
}
.flex-center {
    display: flex;
    align-items: center;
}
/*---------------------------------
-        RC-TABS
-----------------------------------*/
.rc-tabs {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
}
.rc-tabs-bar,
.rc-tabs-nav-container {
    font-size: 14px;
    line-height: 1.5;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    outline: none;
    zoom: 1;
    transition: padding 0.45s;
}
.rc-tabs-ink-bar {
    z-index: 1;
    position: absolute;
    box-sizing: border-box;
    margin-top: -3px;
    background-color: #108ee9;
    transform-origin: 0 0;
    width: 0;
    height: 0;
}
.rc-tabs-ink-bar-animated {
    transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1), left 0.3s cubic-bezier(0.35, 0, 0.25, 1),
        top 0.3s cubic-bezier(0.35, 0, 0.25, 1), height 0.3s cubic-bezier(0.35, 0, 0.25, 1),
        width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.rc-tabs-tab-prev,
.rc-tabs-tab-next {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;
    line-height: 36px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    position: absolute;
}
.rc-tabs-tab-prev-icon,
.rc-tabs-tab-next-icon {
    position: relative;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: inherit;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    font-smoothing: antialiased;
    text-stroke-width: 0;
    font-family: sans-serif;
}
.rc-tabs-tab-prev-icon:before,
.rc-tabs-tab-next-icon:before {
    display: block;
}
.rc-tabs-tab-btn-disabled {
    cursor: default;
    color: #ccc;
}
.rc-tabs-nav-wrap {
    overflow: hidden;
}
.rc-tabs-nav {
    box-sizing: border-box;
    padding-left: 0;
    position: relative;
    margin: 0;
    float: left;
    list-style: none;
    display: inline-block;
    transform-origin: 0 0;
}
.rc-tabs-nav-animated {
    transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
}
.rc-tabs-nav:before,
.rc-tabs-nav:after {
    display: table;
    content: ' ';
}
.rc-tabs-nav:after {
    clear: both;
}
.rc-tabs-tab {
    box-sizing: border-box;
    position: relative;
    display: block;
    transition: color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    padding: 8px 20px;
    font-weight: 500;
    cursor: pointer;
}
.rc-tabs-tab:hover {
    color: #23c0fa;
}
.rc-tabs-tab-active,
.rc-tabs-tab-active:hover {
    color: #108ee9;
    cursor: default;
    transform: translateZ(0);
}
.rc-tabs-tab-disabled {
    cursor: default;
    color: #ccc;
}
.rc-tabs-tab-disabled:hover {
    color: #ccc;
}
.rc-tabs-content {
    zoom: 1;
}
.rc-tabs-content .rc-tabs-tabpane {
    overflow: auto;
}
.rc-tabs-content-animated {
    transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1), margin-left 0.3s cubic-bezier(0.35, 0, 0.25, 1),
        margin-top 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    display: -ms-flexbox;
    display: flex;
    will-change: transform;
}
.rc-tabs-content-animated .rc-tabs-tabpane {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.no-flexbox .rc-tabs-content {
    transform: none !important;
    overflow: auto;
}
.no-csstransitions .rc-tabs-tabpane-inactive,
.no-flexbox .rc-tabs-tabpane-inactive,
.rc-tabs-content-no-animated .rc-tabs-tabpane-inactive {
    display: none;
}
.rc-tabs-left {
    border-right: 2px solid #f3f3f3;
}
.rc-tabs-left .rc-tabs-bar {
    float: left;
    height: 100%;
    margin-right: 10px;
    border-right: 1px solid #f3f3f3;
}
.rc-tabs-left .rc-tabs-nav-container {
    height: 100%;
}
.rc-tabs-left .rc-tabs-nav-container-scrolling {
    padding-top: 32px;
    padding-bottom: 32px;
}
.rc-tabs-left .rc-tabs-nav-wrap {
    height: 100%;
}
.rc-tabs-left .rc-tabs-content-animated {
    -ms-flex-direction: column;
    flex-direction: column;
}
.rc-tabs-left .rc-tabs-content-animated .rc-tabs-tabpane {
    height: 100%;
}
.rc-tabs-left .rc-tabs-nav-scroll {
    height: 99999px;
}
.rc-tabs-left .rc-tabs-nav-swipe {
    position: relative;
    top: 0;
}
.rc-tabs-left .rc-tabs-nav-swipe .rc-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}
.rc-tabs-left .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
}
.rc-tabs-left .rc-tabs-tab-prev,
.rc-tabs-left .rc-tabs-tab-next {
    margin-top: -2px;
    height: 0;
    line-height: 32px;
    width: 0;
    display: block;
    text-align: center;
    opacity: 0;
    transition: width 0.3s, height 0.3s, opacity 0.3s;
}
.rc-tabs-top .rc-tabs-tab-arrow-show,
.rc-tabs-left .rc-tabs-tab-arrow-show,
.rc-tabs-bottom .rc-tabs-tab-arrow-show,
.rc-tabs-right .rc-tabs-tab-arrow-show {
    opacity: 1;
    width: 100%;
    height: 32px;
}
.rc-tabs-left .rc-tabs-tab-next {
    bottom: 0;
}
.rc-tabs-left .rc-tabs-tab-next-icon {
    transform: rotate(90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.rc-tabs-left .rc-tabs-tab-next-icon:before {
    content: '>';
}
.rc-tabs-left .rc-tabs-tab-prev {
    top: 2px;
}
.rc-tabs-left .rc-tabs-tab-prev-icon {
    transform: rotate(270deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.rc-tabs-left .rc-tabs-tab-prev-icon:before {
    content: '>';
}
.rc-tabs-left .rc-tabs-ink-bar {
    width: 2px;
    right: 0;
    top: 0;
}
.rc-tabs-left .rc-tabs-tab {
    padding: 16px 24px;
}
.rc-tabs-right {
    border-left: 2px solid #f3f3f3;
}
.rc-tabs-right .rc-tabs-bar {
    float: right;
    height: 100%;
    margin-left: 10px;
    border-left: 1px solid #f3f3f3;
}
.rc-tabs-right .rc-tabs-nav-container {
    height: 100%;
}
.rc-tabs-right .rc-tabs-nav-container-scrolling {
    padding-top: 32px;
    padding-bottom: 32px;
}
.rc-tabs-right .rc-tabs-nav-wrap {
    height: 100%;
}
.rc-tabs-right .rc-tabs-nav-scroll {
    height: 99999px;
}
.rc-tabs-right .rc-tabs-nav-swipe {
    position: relative;
}
.rc-tabs-right .rc-tabs-nav-swipe .rc-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}
.rc-tabs-right .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
}
.rc-tabs-right .rc-tabs-tab-prev,
.rc-tabs-right .rc-tabs-tab-next {
    margin-top: -2px;
    height: 0;
    width: 0;
    display: block;
    text-align: center;
    line-height: 32px;
    opacity: 0;
    transition: width 0.3s, height 0.3s, opacity 0.3s;
}
.rc-tabs-top .rc-tabs-tab-arrow-show {
    opacity: 1;
    width: 100%;
    height: 32px;
}
.rc-tabs-right .rc-tabs-tab-next {
    bottom: 0;
}
.rc-tabs-right .rc-tabs-tab-next-icon {
    transform: rotate(90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.rc-tabs-right .rc-tabs-tab-next-icon:before {
    content: '>';
}
.rc-tabs-right .rc-tabs-tab-prev {
    top: 2px;
}
.rc-tabs-right .rc-tabs-tab-prev-icon {
    transform: rotate(270deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.rc-tabs-right .rc-tabs-tab-prev-icon:before {
    content: '>';
}
.rc-tabs-right .rc-tabs-content-animated {
    -ms-flex-direction: column;
    flex-direction: column;
}
.rc-tabs-right .rc-tabs-content-animated .rc-tabs-tabpane {
    height: 100%;
}
.rc-tabs-right .rc-tabs-ink-bar {
    width: 2px;
    left: 0;
    top: 0;
}
.rc-tabs-right .rc-tabs-tab {
    padding: 16px 24px;
}
.rc-tabs-bottom {
    border-top: 2px solid #f3f3f3;
}
.rc-tabs-bottom .rc-tabs-content {
    width: 100%;
}
.rc-tabs-bottom .rc-tabs-bar {
    border-top: 1px solid #f3f3f3;
}
.rc-tabs-bottom .rc-tabs-nav-container-scrolling {
    padding-left: 32px;
    padding-right: 32px;
}
.rc-tabs-bottom .rc-tabs-nav-scroll {
    width: 99999px;
}
.rc-tabs-bottom .rc-tabs-nav-swipe {
    position: relative;
    left: 0;
}
.rc-tabs-bottom .rc-tabs-nav-swipe .rc-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
}
.rc-tabs-bottom .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 0;
    padding: 8px 0;
    -ms-flex-pack: center;
    justify-content: center;
}
.rc-tabs-bottom .rc-tabs-nav-wrap {
    width: 100%;
}
.rc-tabs-bottom .rc-tabs-content-animated {
    -ms-flex-direction: row;
    flex-direction: row;
}
.rc-tabs-bottom .rc-tabs-content-animated .rc-tabs-tabpane {
    width: 100%;
}
.rc-tabs-bottom .rc-tabs-tab-next {
    right: 2px;
}
.rc-tabs-bottom .rc-tabs-tab-next-icon:before {
    content: '>';
}
.rc-tabs-bottom .rc-tabs-tab-prev {
    left: 0;
}
.rc-tabs-bottom .rc-tabs-tab-prev-icon:before {
    content: '<';
}
.rc-tabs-bottom .rc-tabs-tab-prev,
.rc-tabs-bottom .rc-tabs-tab-next {
    margin-right: -2px;
    width: 32px;
    height: 100%;
    top: 0;
    text-align: center;
}
.rc-tabs-bottom .rc-tabs-ink-bar {
    height: 2px;
    top: 3px;
    left: 0;
}
.rc-tabs-bottom .rc-tabs-tab {
    float: left;
    height: 100%;
    margin-right: 30px;
}
.rc-tabs-bottom .rc-tabs-tabpane-inactive {
    height: 0;
    overflow: visible;
}
.rc-tabs-top {
    border-bottom: 2px solid #f3f3f3;
}
.rc-tabs-top .rc-tabs-content {
    width: 100%;
}
.rc-tabs-top .rc-tabs-bar {
    border-bottom: 1px solid #f3f3f3;
}
.rc-tabs-top .rc-tabs-nav-container-scrolling {
    padding-left: 32px;
    padding-right: 32px;
}
.rc-tabs-top .rc-tabs-nav-scroll {
    width: 99999px;
}
.rc-tabs-top .rc-tabs-nav-swipe {
    position: relative;
    left: 0;
}
.rc-tabs-top .rc-tabs-nav-swipe .rc-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
}
.rc-tabs-top .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 0;
    padding: 8px 0;
    -ms-flex-pack: center;
    justify-content: center;
}
.rc-tabs-top .rc-tabs-nav-wrap {
    width: 100%;
}
.rc-tabs-top .rc-tabs-content-animated {
    -ms-flex-direction: row;
    flex-direction: row;
}
.rc-tabs-top .rc-tabs-content-animated .rc-tabs-tabpane {
    width: 100%;
}
.rc-tabs-top .rc-tabs-tab-next {
    right: 2px;
}
.rc-tabs-top .rc-tabs-tab-next-icon:before {
    content: '>';
}
.rc-tabs-top .rc-tabs-tab-prev {
    left: 0;
}
.rc-tabs-top .rc-tabs-tab-prev-icon:before {
    content: '<';
}
.rc-tabs-top .rc-tabs-tab-prev,
.rc-tabs-top .rc-tabs-tab-next {
    margin-right: -2px;
    width: 0;
    height: 0;
    top: 0;
    text-align: center;
    opacity: 0;
    transition: width 0.3s, height 0.3s, opacity 0.3s;
}
.rc-tabs-top .rc-tabs-tab-arrow-show {
    opacity: 1;
    width: 32px;
    height: 100%;
}
.rc-tabs-top .rc-tabs-ink-bar {
    height: 2px;
    bottom: 0;
    left: 0;
}
.rc-tabs-top .rc-tabs-tab {
    float: left;
    height: 100%;
    margin-right: 30px;
}
.rc-tabs-top .rc-tabs-tabpane-inactive {
    height: 0;
    overflow: visible;
}
.slick-prev {
    z-index: 9 !important;
    left: 8px;
    transform: scale(1.2);
    opacity: 0.5;
    transition: all 0.3s;
}
.slick-dots {
    bottom: 20px !important;
}
.slick-dots li button:before {
    font-size: 9px;
    color: #fff !important;
}
.slick-next {
    opacity: 0.5;
    right: 8px;
    z-index: 10;
    transform: scale(1.2);
    transition: all 0.3s;
}
.slick-next:before,
.slick-prev:before {
    font-size: 1.5rem;
    line-height: 1;
    opacity: 0.75;
    color: #fff;
}
.slick-prev:hover,
.slick-next:hover {
    opacity: 1;
}
/* Quiz */
.quiz .answer-radio .choice {
    padding: 20px;
    border-bottom: 1px solid #ddd;
}
.quiz .answer-frequency,
.quiz .answer-radio {
    text-align: left;
    overflow: hidden;
}
/* @media only screen and (min-width: 1024px) {
    .quiz .choice {
        border-radius: 3px;
    }
}
@media only screen and (min-width: 1024px) {
    .quiz .answer-radio {
        width: 45%;
    }
}
@media only screen and (min-width: 768px) {
    .quiz .answer-radio {
        width: 60%;
        margin: 0 auto;
    }
} */
.quiz .answer-container {
    position: relative;
}
.quiz .answer-frequency .radio-button,
.quiz .answer-radio .radio-button {
    margin-right: 10px;
}
.radio-button {
    display: inline-block;
    position: relative;
    white-space: nowrap;
}
.radio-button.checked .control {
    border-color: #ff3f7f;
}
.radio-button .control {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 50%;
    vertical-align: middle;
    background-color: #fff;
}
.radio-button.checked .check {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #ff3f7f;
}
.left {
    float: left;
}
.quiz .answer-frequency .radio-button + *,
.quiz .answer-radio .radio-button + * {
    overflow: hidden;
}
.quiz .quiz-questions .carousel {
    height: auto;
    white-space: normal;
    padding-left: 0;
}
.quiz .answer-frequency,
.quiz .answer-radio {
    text-align: left;
    overflow: hidden;
}
.quiz .answer-frequency {
    display: flex;
}
.quiz .answer-frequency,
.quiz .answer-radio {
    text-align: left;
    overflow: hidden;
}
.margin-top-10,
.margin-top-sm {
    margin-top: 10px;
}
.border-bot,
.border-bot-divider {
    border-bottom: 1px solid #ddd;
}
.checkFA {
    display: none;
}
.active .checkFA {
    display: block;
    position: absolute;
    color: #dd637e;
    font-size: 1.2rem;
    opacity: 0.9;
    top: 0;
    right: 5px;
}
.heading-lines {
    overflow: hidden;
    text-align: center;
    font-size: 1.3rem !important;
    text-transform: uppercase;
}
label {
    font-size: 14px;
    color: #525252;
}
.form-control,
.form-control input {
    border-radius: 2px !important;
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #e27c92;
    outline: 0;
    box-shadow: none !important;
}
.pink {
    color: #dd637e;
}
.pink-bg {
    background-color: snow;
}
.FormGroup {
    margin: 0 15px 20px;
    padding: 0;
    border-style: none;
    background-color: #fff;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #fff;
    border-radius: 4px;
}
.FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid #fff;
}
.StripeElement--webkit-autofill {
    background: transparent !important;
}
.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}
.low-opacity {
    opacity: 0.6;
}
.pointer {
    cursor: pointer;
}
.card {
    border-radius: 1px;
}
.flex-right {
    display: flex;
    justify-content: flex-end;
}
.datepicker input {
    box-shadow: none;
    font-size: 15px;
    padding: 0;
}

.datepicker input:focus {
    font-size: 15px;
    padding: 0;
    border-radius: 0px;
}

/*==================================
=      DAYPICKER
====================================*/

.bp3-datepicker {
    position: relative;
    border-radius: 3px;
    background: #fff;
    padding: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bp3-datepicker .DayPicker {
    display: inline-block;
    position: relative;
    min-width: 210px;
    vertical-align: top;
}

.bp3-datepicker .DayPicker:focus {
    outline: none;
}

.bp3-datepicker .DayPicker-Month {
    display: inline-table;
    margin: 0 5px 5px;
    border-collapse: collapse;
    border-spacing: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bp3-datepicker .DayPicker-Month + .bp3-datepicker .DayPicker-Month {
    margin-left: 10px;
}

.bp3-datepicker .DayPicker-Caption {
    display: table-caption;
}

.bp3-datepicker .DayPicker-Weekdays {
    display: table-header-group;
}

.bp3-datepicker .DayPicker-WeekdaysRow {
    display: table-row;
}

.bp3-datepicker .DayPicker-Weekday {
    display: table-cell;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    text-align: center;
    line-height: 1;
    padding-top: 5px;
    font-weight: 600;
}

.bp3-datepicker .DayPicker-Weekday abbr[title] {
    text-decoration: none;
}

.bp3-datepicker .DayPicker-Body {
    display: table-row-group;
}

.bp3-datepicker .DayPicker-Week {
    display: table-row;
}

.bp3-datepicker .DayPicker-WeekNumber {
    color: rgba(92, 112, 128, 0.6);
    font-size: 14px;
}

.bp3-datepicker .DayPicker-Day,
.bp3-datepicker .DayPicker-WeekNumber {
    display: table-cell;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    text-align: center;
    line-height: 1;
}

.bp3-datepicker .DayPicker-Day {
    border-radius: 3px;
    cursor: pointer;
}

.bp3-datepicker .DayPicker-Day.DayPicker-Day--outside {
    color: rgba(92, 112, 128, 0.6);
}

.bp3-datepicker .DayPicker-Day:focus,
.bp3-datepicker .DayPicker-Day:hover {
    background: #d8e1e8;
    color: #182026;
}

.bp3-datepicker .DayPicker-Day:active {
    background: #ced9e0;
}

.bp3-datepicker .DayPicker-Day.DayPicker-Day--selected {
    border-radius: 3px;
    background-color: #137cbd;
    color: #fff;
}

.bp3-datepicker .DayPicker-Day.DayPicker-Day--selected:hover {
    background-color: #106ba3;
}

.bp3-datepicker .DayPicker-Day.DayPicker-Day--disabled {
    background: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.6);
}

.bp3-datepicker-navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 30px;
}

.bp3-datepicker-navbar > .DayPicker-NavButton--prev {
    margin-right: auto;
}

.bp3-datepicker-navbar > .DayPicker-NavButton--next {
    margin-left: auto;
}

.bp3-datepicker-caption {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 25px 5px;
}

.bp3-datepicker-caption > * {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.bp3-datepicker-caption > :first-child {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
}

.bp3-datepicker-caption select {
    padding-right: 16px;
    padding-left: 5px;
    font-weight: 600;
}

.bp3-datepicker-caption select + .bp3-icon {
    right: 2px;
}

.bp3-datepicker-caption + .bp3-divider {
    margin: 0;
}

.bp3-datepicker-month-select,
.bp3-datepicker-year-select {
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
}

.bp3-datepicker-year-select {
    min-width: 60px;
}

.bp3-datepicker-caption-measure {
    padding-left: 5px;
    font-weight: 600;
}

.bp3-datepicker-footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.bp3-dark .bp3-datepicker {
    background: #30404d;
}

.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--outside,
.bp3-dark .bp3-datepicker .DayPicker-WeekNumber {
    color: rgba(167, 182, 194, 0.6);
}

.bp3-dark .bp3-datepicker .DayPicker-Day:focus,
.bp3-dark .bp3-datepicker .DayPicker-Day:hover {
    background: #5c7080;
    color: #fff;
}

.bp3-dark .bp3-datepicker .DayPicker-Day:active {
    background: #738694;
}

.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--selected {
    background-color: #137cbd;
}

.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--selected:hover {
    background-color: #2b95d6;
}

.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--disabled {
    background: none;
    color: rgba(167, 182, 194, 0.6);
}

.bp3-dark .bp3-datepicker .bp3-datepicker-footer {
    border-top-color: rgba(16, 22, 26, 0.4);
}

.bp3-daterangepicker {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
}

.bp3-daterangepicker .DayPicker:first-of-type .bp3-datepicker-navbar {
    left: 5px;
}

.bp3-daterangepicker .DayPicker-NavButton--interactionDisabled {
    display: none;
}

.bp3-daterangepicker .bp3-daterangepicker-timepickers {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.bp3-daterangepicker.bp3-daterangepicker-contiguous .DayPicker {
    min-width: 220px;
}

.bp3-daterangepicker.bp3-daterangepicker-single-month .DayPicker {
    min-width: 210px;
}

.bp3-daterangepicker .DayPicker-Day--outside {
    visibility: hidden;
}

.bp3-daterangepicker .DayPicker-Day--hovered-range {
    border-radius: 0;
}

.bp3-daterangepicker
    .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
    background-color: #ebf1f5;
}

.bp3-daterangepicker .DayPicker-Day--selected-range {
    border-radius: 0;
    background-color: #ebf1f5;
}

.bp3-daterangepicker .DayPicker-Day--selected-range:hover {
    background-color: #ced9e0;
}

.bp3-daterangepicker
    .DayPicker-Day--selected-range-start:not(.DayPicker-Day--selected-range-end):not(.DayPicker-Day--hovered-range-end) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.bp3-daterangepicker
    .DayPicker-Day--selected-range-end:not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--hovered-range-start) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.bp3-daterangepicker .DayPicker-Day--hovered-range-start:not(.DayPicker-Day--hovered-range-end) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.bp3-daterangepicker .DayPicker-Day--hovered-range-end:not(.DayPicker-Day--hovered-range-start) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.bp3-dark
    .bp3-daterangepicker
    .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
    background-color: #394b59;
}

.bp3-dark .bp3-daterangepicker .DayPicker-Day--selected-range {
    background-color: #394b59;
}

.bp3-dark .bp3-daterangepicker .DayPicker-Day--selected-range:hover {
    background-color: #5c7080;
}

.bp3-menu.bp3-daterangepicker-shortcuts {
    min-width: 120px;
    padding: 0;
}

.bp3-timepicker {
    white-space: nowrap;
}

.bp3-timepicker .bp3-timepicker-arrow-row {
    padding: 0 1px;
}

.bp3-timepicker .bp3-timepicker-arrow-button {
    color: #5c7080;
    display: inline-block;
    width: 33px;
    padding: 4px 0;
    text-align: center;
}

.bp3-timepicker .bp3-timepicker-arrow-button:hover {
    color: #182026;
}

.bp3-dark .bp3-timepicker .bp3-timepicker-arrow-button {
    color: #a7b6c2;
}

.bp3-dark .bp3-timepicker .bp3-timepicker-arrow-button:hover {
    color: #f5f8fa;
}

.bp3-timepicker .bp3-timepicker-arrow-button + .bp3-timepicker-arrow-button {
    margin-left: 11px;
}

.bp3-timepicker .bp3-timepicker-arrow-button:hover {
    cursor: pointer;
}

.bp3-timepicker .bp3-timepicker-input-row {
    display: inline-block;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    background: #fff;
    height: 30px;
    padding: 0 1px;
    vertical-align: middle;
    line-height: 28px;
}

.bp3-timepicker .bp3-timepicker-divider-text {
    display: inline-block;
    width: 11px;
    text-align: center;
    color: #5c7080;
    font-size: 16px;
}

.bp3-timepicker .bp3-timepicker-input {
    outline: 0;
    border: 0;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0);
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0);
    background: transparent;
    width: 33px;
    height: 28px;
    padding: 0;
    text-align: center;
    color: #182026;
    -webkit-transition: -webkit-box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
    transition: -webkit-box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
    transition: box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
    transition: box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp3-timepicker .bp3-timepicker-input:focus {
    -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3);
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3);
}

.bp3-timepicker .bp3-timepicker-ampm-select {
    margin-left: 5px;
}

.bp3-timepicker.bp3-disabled .bp3-timepicker-input-row {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.6);
    resize: none;
}

.bp3-timepicker.bp3-disabled .bp3-timepicker-divider-text,
.bp3-timepicker.bp3-disabled .bp3-timepicker-input {
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.6);
}

.bp3-timepicker.bp3-disabled .bp3-timepicker-arrow-button,
.bp3-timepicker.bp3-disabled .bp3-timepicker-arrow-button:hover {
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.6);
}

.bp3-dark .bp3-timepicker .bp3-timepicker-input-row {
    -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    background: rgba(16, 22, 26, 0.3);
}

.bp3-dark .bp3-timepicker .bp3-timepicker-divider-text {
    color: #a7b6c2;
}

.bp3-dark .bp3-timepicker .bp3-timepicker-input {
    color: #f5f8fa;
}

.bp3-datepicker .bp3-timepicker {
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: center;
}

.bp3-datepicker .bp3-timepicker:last-child {
    margin-bottom: 5px;
}

.bp3-datetimepicker {
    border-radius: 3px;
    background-color: #fff;
    padding: 10px;
    text-align: center;
}

.bp3-dark .bp3-datetimepicker {
    background: #30404d;
}

.bp3-dark .bp3-datetimepicker .bp3-datepicker {
    border-bottom: 1px solid rgba(16, 22, 26, 0.4);
}

.bp3-datetimepicker .bp3-datepicker {
    border-bottom: 1px solid rgba(16, 22, 26, 0.15);
    padding: 0 0 10px;
}

.bp3-datetimepicker .bp3-timepicker {
    margin-top: 10px;
}

.bp3-dateinput-popover {
    padding: 0;
}
